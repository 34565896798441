import React from 'react';
import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { adaptImage } from '@src/adapters/image';
import ContactForm from '@src/components/contact-form';
import { useSiteMetadata } from '@src/hooks/site-metadata';
import LayoutFromContent from '@src/layouts/layout-from-content';
import { Title } from '@src/ui';
import { formatPhone, i18nPhone } from '@src/services/strings';
import { ContactQuery } from '@types';
import styles from './contact.module.scss';
import { SecteurContentCities } from '../components/cities';

interface ContactProps {
  data: ContactQuery;
}

const ContactPage: React.FC<ContactProps> = ({ data }) => {
  const { phone, email, address } = useSiteMetadata();
  const contactImage = adaptImage(data.contactImage);

  return (
    <LayoutFromContent pageId="contact">
      <div className={styles.page}>
        <section className={styles.infos}>
          <div className={styles.contact}>
            <div className={styles.section}>
              <Title hLevel={1}>Prendre rendez-vous</Title>
              {phone && (
                <div className={styles.coordinates}>
                  <h2 className={styles.coordinatesTitle}>Par téléphone</h2>
                  <OutboundLink
                    className={styles.cta}
                    href={`tel:${i18nPhone(phone)}`}
                    eventCategory="CTA"
                    eventAction="Click"
                    eventLabel="Phone"
                  >
                    {formatPhone(phone)}
                  </OutboundLink>
                </div>
              )}
              {email && (
                <div className={styles.coordinates}>
                  <h2 className={styles.coordinatesTitle}>Par email</h2>
                  <OutboundLink
                    className={styles.cta}
                    href={`mailto:${email}`}
                    eventCategory="CTA"
                    eventAction="Click"
                    eventLabel="Email"
                  >
                    {email}
                  </OutboundLink>
                </div>
              )}
            </div>
            <div className={styles.sectionImage}>
              <GatsbyImage
                className={styles.image}
                fluid={contactImage}
                alt={``}
              />
            </div>
            <div className={styles.section}>
              <Title hLevel={2}>Où me trouver</Title>
              <div>Retrouvez-moi aux Marches, à 10 minutes de Chambéry :</div>
              <address className={styles.address}>{address}</address>
              {address && (
                <div className={styles.goto}>
                  <OutboundLink
                    className={styles.cta}
                    target="_blank"
                    href={`https://www.google.com/maps/dir/?api=1&destination=${address}`}
                    eventCategory="CTA"
                    eventAction="Click"
                    eventLabel="Address"
                  >
                    Voir la carte
                  </OutboundLink>
                </div>
              )}
            </div>
          </div>

          <div className={styles.alert}>
            <Title hLevel={2}>Consultations ostéopathiques uniquement</Title>
            <div>
              J&apos;exerce exclusivement en ostéopathie et phytothérapie et ne
              réalise donc aucun acte de médecine vétérinaire courante. En cas
              d&apos;urgence ou de nécessité de soins autre que
              l&apos;ostéopathie merci de contacter votre vétérinaire traitant
              ou, si votre animal ne possède pas de vétérinaire traitant, de
              joindre le CHV Saint-Martin au 04.50.600.900.
            </div>
          </div>

          <div className={styles.cities}>
            <SecteurContentCities title="Villes à proximité" />
          </div>
        </section>

        <ContactForm />
      </div>
    </LayoutFromContent>
  );
};

export const query = graphql`
  query Contact {
    contactImage: file(relativePath: { eq: "contact.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 240, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default ContactPage;
